/** Regular expression for validating an email address. Taken from http://emailregex.com/. */
const emailAddressRegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

/** Regular expression for validating a US or Canadian phone number. Taken from https://phoneregex.com/. */
const phoneNumberRexExp = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/

/** Ensure that string literals referencing property names on models or interfaces are correct.
 *  Will generate a compile-time error if the given property name does not exist on type `T`.
 */
export function propertyOf<T>(name: keyof T) { return name; }

/** Returns true if the provided string is a legally-formatted email address. */
export function isValidEmailAddress(value: string): boolean {
    return emailAddressRegExp.test(value);
}

/** Returns true if the provided string is a legally-formatted US or Canadian phone number. */
export function isValidPhoneNumber(value: string, requireAreaCode: boolean = true): boolean {
    if (requireAreaCode) {
        return value.length > 7 && phoneNumberRexExp.test(value);
    }
    return phoneNumberRexExp.test(value);
}

/** Returns a human-friendly formatted phone number. Only guaranteed to work for standard 7- or 10-digit USA and CA phone numbers. */
export function prettyPhoneNumber(value: string): string {
    if (!value || !value.length) { return ''; }

    let i = 0;
    let ret = '';
    if (value.startsWith('1')) {
        // country code for USA and CA
        ret += '+1-';
        i += 1;
    }
    if (value.length > 7) {
        // area code
        ret += value.substr(i, 3) + '-';
        i += 3;
    }

    // last 7 digits of the number, XXX-XXXX
    ret += value.substr(i, 3) + '-';
    i += 3
    ret += value.substr(i);

    return ret;
}