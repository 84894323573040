import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild  } from "@angular/core";
import { Observable, Subject, asyncScheduler, firstValueFrom, from, of, shareReplay, switchMap} from 'rxjs';
import { CustomerInformation } from 'src/models/customer';
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { ApiService } from 'src/services/api.service';
import { catchError, map, takeUntil, tap} from "rxjs/operators";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { faAngleDown, faCircle } from "@fortawesome/free-solid-svg-icons";
import { AppSettingsService } from "../app-settings/app-settings.service";
import { CustomerStateService } from "src/services/customer-state.service";
import { HttpClient } from "@angular/common/http";
import { SiteInformation, ModalSiteInfo } from "src/models/site-information";
import { LocationInfo } from "src/models/location-info";
import { BuyThisMonthlyPassSvgComponent } from "../shared/svgs/buy-this-monthly-pass/buy-this-monthly-pass.svg.component";

@Component({
  selector: 'app-site-landing',
  templateUrl: './site-landing.component.html',
  styleUrls: ['./site-landing.component.scss']
})
export class SiteLandingComponent implements OnInit, OnDestroy {
  faAngleDown = faAngleDown;
  faCircle = faCircle;

  public customerId: Observable<string>
  public customerInformation: Observable<CustomerInformation>;

  public sites$: Observable<SiteInformation[]>;
  public modalSites$ = new Observable<ModalSiteInfo[]>(null);
  public inputValue: string;
  selectedIndex: number = -1;
  
  private readonly onDestroy$ = new Subject<void>();

  public isProduction: boolean = false;

  public explainerVideoLink: SafeResourceUrl = "https://www.youtube.com/embed/q81v7wEpLgU";
  
  public footerLogo: SafeResourceUrl;

  public canContinue: boolean = false;

  public mobileDownload: SafeResourceUrl;

  private readonly mapsApiKey: string;

  public info: LocationInfo = {lat: 0, lon: 0}

  public favoredSite$: Observable<SiteInformation | null> 

  @ViewChild('aboutText') aboutTextElement: ElementRef;
  @ViewChild('explainerVideo') explainerVideoElement: ElementRef;

  constructor(
    private apiService: ApiService,
    public customerStateService: CustomerStateService,
    private router: Router,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private http: HttpClient,
    private appSettingsService: AppSettingsService,

    private host: ElementRef<HTMLElement>) { 
      this.mapsApiKey =`${this.appSettingsService.googleMapsKey}`;
      this.modalSites$ = this.apiService.modalSites$.pipe(shareReplay(1), tap());
      this.isProduction = this.appSettingsService.isProduction;
    }


  async ngOnInit() {
    this.favoredSite$ = from(this.getfavoredSite())
    var tempCustomerDashboard = await this.customerStateService.getCurrentCustomerInfo()
    this.customerInformation = await this.customerStateService.currentCustomerInfo$.pipe( 
      map(customerDashboard => {
        if (customerDashboard == null)
          {
            customerDashboard = tempCustomerDashboard
          }
      this.footerLogo = this.sanitizer.bypassSecurityTrustUrl('data:image/svg;base64,' + customerDashboard.image);
      this.setNewActiveSiteClick(customerDashboard.favoredSite)
      if(customerDashboard.mobileDownload != null){
        this.mobileDownload = this.sanitizer.sanitize(4,customerDashboard.mobileDownload);
      }
      // populate the customer styles/video link
      if(customerDashboard.explainerVideoLink != null && customerDashboard.explainerVideoLink.startsWith("https://www.youtube.com/embed/"))
      {
        this.explainerVideoLink = this.sanitizer.bypassSecurityTrustResourceUrl(customerDashboard.explainerVideoLink);
      } else if (customerDashboard.explainerVideoLink != null) {
        this.explainerVideoLink = this.sanitizer.bypassSecurityTrustResourceUrl(customerDashboard.explainerVideoLink.toString().replace("watch?v=", "embed/"));
      }
      else
      {
        this.explainerVideoLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.explainerVideoLink.toString())
        this.adjustVideoSize()
      }

      if (customerDashboard.aboutUsDescription == null || customerDashboard.aboutUsDescription == "")
      {
        customerDashboard.aboutUsDescription = "Thank you for your monthly wash pass purchase. Your pass includes 15 washes per month. Passes automatically renew each month. Cancel/suspend your pass anytime in the app"
        this.adjustVideoSize()
      }

      return customerDashboard;
    })
    );
  }

  async getfavoredSite() {
    
    var siteInformation: SiteInformation;
    var siteId = sessionStorage.getItem("siteId");
    var customerId = sessionStorage.getItem("customerId");

    var sites = await firstValueFrom(this.apiService.getCustomerSites(customerId))
    var customer = await firstValueFrom(this.apiService.getCustomerInformation(customerId))


    if (siteId != null && siteId !== "")
    {
      siteInformation = sites.find(t => t.id === siteId)
    }
    else 
    {
      if (customer.favoredSite != null && customer.favoredSite !== "")
      {
        siteInformation = sites.find(t => t.id === customer.favoredSite) ?? null
      }
      else
      {
        siteInformation = sites[0]
      }
    }
    // this.siteId = siteInformation.id;
    
    return siteInformation;
  }

  CloseModal() {
    const modalDiv = document.getElementById("wash-select-modal");
      if (modalDiv != null) {
        modalDiv.style.display = "none";
      }
  }

  async washPassButtonClick() {
    //if loaded go to pass selection. 
    this.router.navigate([`/customer/${this.customerStateService.customerId}/passes`, "pass-selection"]);
    
  }

  toggleBackground(index: number): void {
    this.selectedIndex = index;
  }

  async membershipButtonClick() {
    this.router.navigate([`/customer/${this.customerStateService.customerId}/login`]);
  }

  contactButtonClick() {
    this.router.navigate([`/customer/${this.customerStateService.customerId}/contact-us`]);
  }

  termsButtonClick() {
    this.router.navigate([`/customer/${this.customerStateService.customerId}/terms-of-service`])
  }
  setNewActiveSiteClick(siteId: string){
    this.customerStateService.forceSetCurrentActiveSite(siteId);
    this.canContinue = true;
  }

  getCustomerIdFromSite(customerId: Observable<string>) {
    this.customerId = customerId;
    return customerId;
  }

  adjustVideoSize() {
    const aboutTextHeight = this.aboutTextElement.nativeElement.offsetHeight;
    const explainerVideoElement = this.explainerVideoElement.nativeElement;

    // Adjust the size of the video container based on the height of the text
    if (aboutTextHeight < explainerVideoElement.offsetHeight)
    {
      explainerVideoElement.style.height = explainerVideoElement.offsetHeight + 'px';
    }
    else
    {
      explainerVideoElement.style.height = aboutTextHeight + 'px';
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
