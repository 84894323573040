import { Component, OnInit, ElementRef, OnDestroy, asNativeElements, HostBinding } from '@angular/core';
import { Observable, Subject, firstValueFrom, map, switchMap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { TextReceiptInformation } from 'src/models/text-receipt-information';
import { ApiService } from 'src/services/api.service';
import { MobileService } from "src/services/mobile.service";
import { WNTransactionResponses } from 'src/models/worldnet-models';

@Component({
  selector: 'app-reciept',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {
  @HostBinding('style.--primaryColor')

  isMobile: boolean;
  public isLoaded: boolean = false;

  private readonly onDestroy$ = new Subject<void>();

  public siteId: string;
  public customerId: string;

  public passName: string;
  public passOverRideName: string;

  public customerName: string;
  public description: string;
  public email: string;
  public phoneNumber: string;
  public additionalUrl: string;
  public visualUrl: string;
  public image: SafeResourceUrl;
  public backgroundImage: string;
  public siteAddress1: string;
  public siteAddress2?: string;
  public siteLocation1: string;
  public paymentGuid: string;
  public paymentSurchargeAmount: number;
  public paymentTaxAmount: number;
  public paymentAmount: number;
  public paymentTotalAmount: number;
  public paymentTimestamp: string;

  public primaryTextColor: string;
  public secondaryTextColor: string;
  public primaryColor: string;
  public neutralBgColor: string;
  public secondaryBgColor: string;
  public additionalProperty1: string;
  public additionalProperty2: string;

  public instagramLink: string;
  public facebookLink: string;
  public youtubeLink: string;
  public mobileDownload: string;

  public textReceiptInformation: TextReceiptInformation;
  public textReceipt$: Observable<TextReceiptInformation>;
  public paymentId$: Observable<string>;

  constructor(
    private apiService: ApiService,
    public sanitizer:DomSanitizer,
    private route: ActivatedRoute,
    private mobileService: MobileService,
    private host: ElementRef<HTMLElement>
  ) {
    this.isMobile = mobileService.isMobile();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  async ngOnInit() {
    this.paymentId$ = this.route.params.pipe(
      map(params => params['id'])
    );
    // paramMap.subscribe(async (params) => {
    //   this.paymentGuid = params.get('id');

    //   console.log(this.paymentGuid);
    // });
    this.textReceiptInformation = await firstValueFrom(this.paymentId$.pipe(
      switchMap(paymentGuid => this.apiService.setTextReceiptInformation(paymentGuid))
    ))

      if(this.textReceiptInformation != null)
      {

        this.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/svg;base64,' + this.textReceiptInformation.image);
        this.backgroundImage = "url('data:image/svg;base64," + this.textReceiptInformation.backgroundImage + "')";

        
        this.facebookLink   = this.textReceiptInformation.facebookLink ?? null; 
        this.instagramLink  = this.textReceiptInformation.instagramLink ?? null;
        this.youtubeLink    = this.textReceiptInformation.youtubeLink ?? null;
        this.mobileDownload = this.textReceiptInformation.mobileDownload ?? null;
        

        this.primaryColor = "#" + this.textReceiptInformation.primaryColor;

        this.primaryTextColor = "#" + this.textReceiptInformation.primaryColor + " !important";

        this.siteAddress1 = this.textReceiptInformation.siteAddress1;
        this.siteAddress2 = this.textReceiptInformation.siteAddress2 ?? "";
        this.siteLocation1 = this.textReceiptInformation.siteLocation1;
        this.paymentTaxAmount = this.textReceiptInformation.paymentTaxAmount;
        this.paymentSurchargeAmount = this.textReceiptInformation.paymentSurchargeAmount;
        this.paymentAmount = this.textReceiptInformation.paymentAmount;
        // Replace with a total amount calculated on the api
        this.paymentTotalAmount = this.textReceiptInformation.paymentTaxAmount + this.textReceiptInformation.paymentSurchargeAmount + this.textReceiptInformation.paymentAmount;
        this.paymentTimestamp = this.textReceiptInformation.paymentTimestamp;
        this.additionalUrl = this.textReceiptInformation.additionalUrl;

        this.passName = this.textReceiptInformation.passName;
        if (this.passName.includes("bronze"))
        {
          this.passOverRideName = this.textReceiptInformation.bronzeTierNameOverride
        }
        if (this.additionalUrl != null)
        {
          //this one is used to display the additionalUrl without "https:// and the trailing "/""
          if (this.additionalUrl.includes("https")) 
          {
            var tempUrl = this.textReceiptInformation.additionalUrl.replace("https://", "");
            //replace trailing "/" only with empty "". All other characters are left unchanged
            this.visualUrl = tempUrl.replace(/\/(?=[^\/]*$)/, "");
          }
          else
          {
            var tempUrl = this.textReceiptInformation.additionalUrl.replace("http://", "");
            //replace trailing "/" only with empty "". All other characters are left unchanged
            this.visualUrl = tempUrl.replace(/\/(?=[^\/]*$)/, "");
          }
        }
        this.additionalProperty1 = this.textReceiptInformation.additionalProperty1;
        this.additionalProperty2 = this.textReceiptInformation.additionalProperty2;
        this.phoneNumber = this.textReceiptInformation.phoneNumber;

        this.isLoaded = true;
      }
  }
}