import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild  } from "@angular/core";
import { Observable, Subject, of, shareReplay, switchMap} from 'rxjs';
import { CustomerInformation } from 'src/models/customer';
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { ApiService } from 'src/services/api.service';
import { catchError, map, takeUntil, tap} from "rxjs/operators";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { faAngleDown, faCircle } from "@fortawesome/free-solid-svg-icons";
import { AppSettingsService } from "../app-settings/app-settings.service";
import { CustomerStateService } from "src/services/customer-state.service";
import { MapGeocoder } from '@angular/google-maps';
import { HttpClient } from "@angular/common/http";
import { SiteInformation, ModalSiteInfo } from "src/models/site-information";
import { LocationInfo } from "src/models/location-info";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
  providers: []
})

export class LandingPageComponent implements OnInit, OnDestroy, AfterViewInit {
  faAngleDown = faAngleDown;
  faCircle = faCircle;

  public customerId: Observable<string>
  public customerInformation: CustomerInformation = null;
  public customerInformation$ = new Observable<CustomerInformation>(null);

  public sites$: Observable<SiteInformation[]>;
  public modalSites$ = new Observable<ModalSiteInfo[]>(null);
  public inputValue: string;
  selectedIndex: number = -1;
  
  private readonly onDestroy$ = new Subject<void>();

  public explainerVideoLink: SafeResourceUrl = "https://www.youtube.com/embed/q81v7wEpLgU";
  
  public primaryColor: string;
  public primaryTextColor: string;
  public neutralBgColor: string;
  public secondaryBgColor: string;
  public secondaryTextColor: string;
  public canContinue: boolean = false;

  private readonly mapsApiKey: string;

  public info: LocationInfo = {lat: 0, lon: 0}

  public isProduction: boolean = false;

  public innerWidth: number = window.innerWidth

  constructor(
    private apiService: ApiService,
    public customerStateService: CustomerStateService,
    private router: Router,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public geocoder: MapGeocoder,
    private http: HttpClient,
    private appSettingsService: AppSettingsService,
    private host: ElementRef<HTMLElement>
    ) { 
      this.mapsApiKey =`${this.appSettingsService.googleMapsKey}`;
      this.modalSites$ = this.apiService.modalSites$.pipe(shareReplay(1), tap());
      this.isProduction = this.appSettingsService.isProduction;
    }

  async ngOnInit() {
    const customerId$ = this.route.params.pipe(
      map(t => t.customerId),
    );
    sessionStorage.clear()
    customerId$.pipe(takeUntil(this.onDestroy$)).subscribe(async customerId => await this.customerStateService.pushCustomerId(customerId));
  }

  onKeyUp(event: { target: { value: string; }; }) {
    this.inputValue = event.target.value;
    if(this.inputValue.length === 5){

      this.geocoder.geocode({
        address: this.inputValue
      }).subscribe(({results}) => {
        this.info.lat = results[0].geometry.location.lat();
        this.info.lon = results[0].geometry.location.lng();
      });
      this.customerStateService.setMapResponse(this.info);

      this.modalSites$ = this.customerStateService.modalSites$;
    }
    else
    {
      this.modalSites$ = null;
    }
  }

  ngAfterViewInit(){
      this.http.jsonp('https://maps.googleapis.com/maps/api/js?key='+ this.mapsApiKey, 
        'callback')
        .pipe(
           map(() => true),
           catchError(() => of(false)),
        );
  }

  CloseModal() {
    const modalDiv = document.getElementById("wash-select-modal");
      if (modalDiv != null) {
        modalDiv.style.display = "none";
      }
  }

  async washPassButtonClick() {
    // Add your button click logic here
    console.log("Buy Button clicked!");
    
    //If not loaded
      //open the modal.
      const modalDiv = document.getElementById("wash-select-modal");
      const modalBg = document.getElementById("modal-background");
      if (modalDiv != null) {
        modalDiv.style.display = "block";
        modalBg.style.position = "fixed";
        modalBg.style.top = "0";
        modalBg.style.bottom = "0";
        modalBg.style.right = "0";
        modalBg.style.left = "0";
        modalBg.style.backgroundColor = "#000";
        modalBg.style.opacity = "0.75";
        modalBg.style.zIndex = "-1";
      }
      //show the closest 5 sites. (api call.)
      //android app uses this api call. 
      //pick a site and then re-navigate to the landing page
      //this time with the customer id of the site selected.

  }

  toggleBackground(index: number): void {
    this.selectedIndex = index;
  }

  membershipButtonClick() {
    console.log("Manage Membership Button clicked!");
  }

  contactButtonClick() {
    console.log("Contact Us Button clicked!");
  }

  termsButtonClick() {
    this.router.navigate(["terms-of-service"]);
  }

  setNewActiveSiteClick(siteId: string){
    this.customerStateService.forceSetCurrentActiveSite(siteId);
    this.canContinue = true;
  }

  getCustomerIdFromSite(customerId: Observable<string>) {
    this.customerId = customerId;
    return customerId;
  }

  redirectToCustLandingPage() {
    this.customerStateService.setCurrentSite();
    this.router.navigate(["customer", this.customerId]);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

}