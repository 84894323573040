import { Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Debounce } from 'src/app/utils/method-decorators';
import { propertyOf } from 'src/app/utils/string-helpers';
import { ICustomerPass } from 'src/models/customer-pass';
import { ISiteProduct, ISiteProductDescription } from 'src/models/site-product';
import { PassPurchaseService } from 'src/app/pass-purchase/pass-purchase.service';
import { ApiService } from 'src/services/api.service';
import { CustomerInformation } from 'src/models/customer';
import { CustomerStateService } from 'src/services/customer-state.service';

@Component({
    selector: 'dencar-purchase-footer',
    templateUrl: './purchase-footer.component.html',
    styleUrls: ['./purchase-footer.component.scss']
})
export class PurchaseFooterComponent  {
    public customerInformation: CustomerInformation = null;

    @Input() pass: ICustomerPass;
    @Input() index: number;
    @Input() months: number = null;  
    
    @Input() products: ISiteProduct[];
    public tier: string;
    public productDescriptions: ISiteProductDescription[];
    public passPrice: number = null;
    public taxAmount: number = null;
    public surchargeAmount: number = null;
    public passTotalPrice: number = null;
    public priceOff: number;

    // customer dynamic styles/images
    public tier4BannerColor: string;
    public tier3BannerColor: string;
    public tier2BannerColor: string;
    public tier1BannerColor: string;

    @HostListener('window:resize')
    @Debounce()
    windowResize() {
    }
    constructor(
        private passPurchaseService: PassPurchaseService
    ) 
        {}


    ngOnInit(){
        this.passPurchaseService.price.subscribe(
            data => {
                this.passPrice = data;
            }
        );
        this.passPurchaseService.tax.subscribe(
            data => {
                this.taxAmount = this.passPrice * data;
            }
        );
        this.passPurchaseService.surcharge.subscribe(
            data => {
                this.surchargeAmount = this.passPrice * data;
            }
        );
        this.passPurchaseService.priceOff.subscribe(
            data => {
                this.priceOff = data;
            });

        this.passTotalPrice = this.passPrice + this.taxAmount + this.surchargeAmount
        if(this.pass.products.find(t => t.productTemplateId === this.passPurchaseService.platinum)) { this.tier = 'platinum'; }
        else if(this.pass.products.find(t => t.productTemplateId === this.passPurchaseService.gold)) { this.tier = 'gold'; }
        else if(this.pass.products.find(t => t.productTemplateId === this.passPurchaseService.silver)) { this.tier = 'silver'; } 
        else if (this.pass.products.find(t => t.productTemplateId === this.passPurchaseService.bronze)) { this.tier = 'bronze'; }
    }

    ngOnChanges(changes: SimpleChanges) {//angular component input onChanges
        const productPropertyName = propertyOf<PurchaseFooterComponent>('products');
        const indexPropertyName = propertyOf<PurchaseFooterComponent>('index');

        if (changes.hasOwnProperty(productPropertyName)) {
            const change = changes[productPropertyName];
            if (change.currentValue === null || change.currentValue === undefined) {
                this.products = [];
            } else if (!(change.currentValue instanceof Array)) {
                this.products = new Array<ISiteProduct>(change.currentValue);
            }
        }
        // if (changes.hasOwnProperty(indexPropertyName)) {
        //     const change = changes[indexPropertyName];
        //     const currentValue = change.currentValue as number;
        //     switch (currentValue) {
        //         case 0:  this.tier = 'platinum'; break;
        //         case 1:  this.tier = 'gold'; break;
        //         case 2:  this.tier = 'silver'; break;
        //         case 3:  this.tier = 'bronze'; break;
        //         default: this.tier = 'gold'; break;
        //     }
        // }
    }

    ngAfterViewInit() {
    }
}
