import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ISiteProductDescription } from 'src/models/site-product';
import { ApiService } from 'src/services/api.service';

@Component({
    selector: 'dencar-product-description-image',
    templateUrl: './product-description-image.component.html',
    styleUrls: ['./product-description-image.component.scss']
})
export class ProductDescriptionImageComponent implements OnInit {
    @Input() description: ISiteProductDescription;

    imageData: SafeResourceUrl;

    constructor(private apiService: ApiService, private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.apiService.getDescriptionImage(this.description.pictureKey).subscribe(
            blob => {
                const fileReader = new FileReader();
                const loadHandler = () => {
                    this.imageData = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/svg+xml;base64,${fileReader.result}`);
                    fileReader.removeEventListener('load', loadHandler);
                };
                fileReader.addEventListener('load', loadHandler);
                fileReader.readAsText(blob);
            },
            error => console.error(error)
        );
    }

}
