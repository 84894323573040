import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { CustomerInformation } from 'src/models/customer';
import { ApiService } from 'src/services/api.service';
import { CustomerStateService } from 'src/services/customer-state.service';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';

@Component({
    selector: 'dencar-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, OnDestroy {
    isMobile: boolean;
    public isLoaded: boolean = false;
    public backgroundImageExists: boolean = false;
    public imageExists: boolean = false;
    private readonly onDestroy$ = new Subject<void>();

    
    @Input() height: string = '225px';
    public image: SafeResourceUrl;
    public backgroundImage: string;
    public noContextLogo: SafeResourceUrl
    public myWashClub: SafeResourceUrl

    public isProduction: boolean = false;

    public innerWidth: number = window.innerWidth

    constructor(
        private apiService: ApiService,
        public customerStateService: CustomerStateService,
        public sanitizer:DomSanitizer,
        private host: ElementRef<HTMLElement>,
        private appSettingsService: AppSettingsService,
    ){
        this.isProduction = this.appSettingsService.isProduction;
    }

    async ngOnInit() {

        this.noContextLogo = this.sanitizer.bypassSecurityTrustResourceUrl('./assets/WashMembership_SVG.svg');
        this.myWashClub = this.sanitizer.bypassSecurityTrustResourceUrl('./assets/WashPass_transparentCentered.svg');

        this.customerStateService.currentCustomerInfo$.pipe(takeUntil(this.onDestroy$)).subscribe( customerDashboard =>{
            if(customerDashboard)
            {
 
                if (customerDashboard.image)
                {
                    // populate the customer logo(image)
                    this.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/svg;base64,' + customerDashboard.image);
                    this.imageExists = true;              
                }
                else
                {
                    this.image = null;
                }
        
                // populate the customer header background image and logo(image)
                if(customerDashboard.backgroundImage)
                {
                    this.backgroundImage = 'data:image/svg;base64,' + customerDashboard.backgroundImage;
                    this.backgroundImageExists = true;         
                }
                else {
                    this.backgroundImage = null;
                }
                this.isLoaded = true;
                
            }
            else
            {
                this.image = null;
                this.backgroundImage = null;
                this.isLoaded = false;
            }
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
