import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/services/api.service';
import { AlertService } from 'src/services/alert.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { CustomerInformation } from 'src/models/customer';
import { IConsumer } from 'src/models/consumer';
import { ConsumerLogin } from 'src/models/consumer-login';

export class CustomerSelection {
  public id: string;
  public name: string;

  constructor(customerInfo: CustomerInformation) {
    this.id = customerInfo.id;
    this.name = customerInfo.name;
  }
}


@Component({
  selector: 'app-account-deletion',
  templateUrl: './account-deletion.component.html',
  styleUrls: ['./account-deletion.component.scss']
})
export class AccountDeletionComponent implements OnInit {
  modalRef?: BsModalRef;
  successModal?: BsModalRef;
  deletionModal?: BsModalRef;
  phoneNumber!: FormGroup;
  loginForm!: FormGroup;
  loading = false;
  submitted = false;

  public customers: CustomerInformation[] = null;
  public consumerLogin: ConsumerLogin;
  public selectedCustomerId: string ;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private alertService: AlertService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      customerId: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.pattern('[- +()0-9]{10,12}')]],
      password: ['', Validators.required]
    });
    this.phoneNumber = this.formBuilder.group({
        phoneNumber: ['', [Validators.required, Validators.pattern('[- +()0-9]{10,12}')]],
    });
  }

  get numberValue() { return this.phoneNumber.controls; }
  get loginValue() { return this.loginForm.controls; }

  changeCustomer(e){
    this.loginForm.controls['customerId'].setValue(e.target.value, {
      onlySelf: true
    })
  }
  onConfirmDeletion(toOpen: TemplateRef<any>){
    this.loading = true;

    this.successModal.hide();
    this.deletionModal = this.modalService.show(toOpen);

    if(confirm("Please confirm that you are requesting your account information to be deleted.")) {
      this.apiService.consumerDeleteRequest(this.consumerLogin.consumer.id)
      .pipe(first()).subscribe( 
        ret => {
          this.loginForm.reset();
          this.phoneNumber.reset();
        },
        error=>{
          this.alertService.error(error);
          console.log(error)
        });
      this.loading = false;
    }
  }

  onLogin(toOpen: TemplateRef<any>) {
    this.submitted = true;
    this.alertService.clear();

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.apiService.login(this.loginValue.phoneNumber.value, this.loginValue.password.value, this.loginValue.customerId.value)
      .pipe(first()).subscribe( 
      consumer => {
        this.loginForm.setErrors({ unauthenticated: false });

        this.loading = false;
        this.modalRef.hide()
        
        this.consumerLogin = consumer;
        this.successModal = this.modalService.show(toOpen)
      },
      error => {
        this.loading = false; 
        this.loginForm.setErrors({ unauthenticated: true });
        this.loginForm.controls['password'].setValue('');
        this.alertService.error(error);
      });
  }
  onSubmit(template: TemplateRef<any>) {
    this.submitted = true;

    if (this.phoneNumber.invalid) {
      return;
    }
    this.loading = true;
  
    this.apiService.consumerCustomers(this.numberValue.phoneNumber.value).pipe(first()).subscribe(consumerCustomers => {
      this.customers = consumerCustomers;

      this.loading = false;
      this.modalRef = this.modalService.show(template);
      this.loginForm.controls['phoneNumber'].setValue(this.numberValue.phoneNumber.value);

      this.submitted = false;
    },
    error => {
      this.alertService.error(error);
      this.loading = false;
    });
  }

}
