import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Subject, map, takeUntil } from 'rxjs';
import { CustomerStateService } from 'src/services/customer-state.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent  implements OnInit {

  private readonly onDestroy$ = new Subject<void>();

  constructor(private router: Router, private route: ActivatedRoute,
    public customerStateService: CustomerStateService) { }


  async ngOnInit () {

    // const consumerId$ = this.route!.paramMap.pipe(map((params: ParamMap) => params.get('consumerId')!))
    // consumerId$.pipe(takeUntil(this.onDestroy$)).subscribe(async consumerId => await this.customerStateService.setCurrentConsumerId(consumerId));
  }
  
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
