import { Component, OnInit } from '@angular/core';
import { CustomerStateService } from 'src/services/customer-state.service';
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import {Location} from '@angular/common';
import { SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  faEnvelope = faEnvelope;
  faPhone = faPhone;

  private explainerVideoLink: string = "https://www.youtube.com/embed/q81v7wEpLgU";


  constructor( 
    public customerStateService: CustomerStateService,
    private _location: Location) {


  }
  ngOnInit(){
    
  }

  emailUs(emailTarget: string){
    var formattedBody = "FirstLine \n Second Line \n Third Line";
    var mailToLink = `mailto:${emailTarget}?body=` + encodeURIComponent(formattedBody);
    window.location.href = mailToLink;
  }

  messageUs(smsTarget: string){
    var formattedBody = "FirstLine \n Second Line \n Third Line";
    var mailToLink = `smsto:${smsTarget}`
    // var mailToLink = `smsto:${smsTarget}:body=` + encodeURIComponent(formattedBody); android
    window.location.href = mailToLink;
  }

  callUs(callTarget: string){
    var formattedBody = "FirstLine \n Second Line \n Third Line";
    var mailToLink = `tel:${callTarget}`
    window.location.href = mailToLink;
  }

  goToLink(url: string){
    if(url == undefined){
      window.open(this.explainerVideoLink, "_blank");
    } else {
      window.open(url, "_blank");
    }
  }

  back(): void {
    this._location.back();
  }
}
