import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject, first, firstValueFrom, map, switchMap, takeUntil } from 'rxjs';
import { Addresses } from 'src/models/addresses';
import { IConsumer } from 'src/models/consumer';
import { ConsumerLogin } from 'src/models/consumer-login';
import { AlertService } from 'src/services/alert.service';
import { ApiService } from 'src/services/api.service';
import { CustomerStateService } from 'src/services/customer-state.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {
  consumerForm!: FormGroup;
  
  public customerId$: Observable<string>;
  public customerId: string;
  public consumerId$: Observable<string>;
  public consumer$: Observable<IConsumer>;
  private readonly onDestroy$ = new Subject<void>();
  public consumer: IConsumer;
  public viewAddresses: Addresses[];
  public consumerLogin: ConsumerLogin;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private alertService: AlertService,
    private modalService: BsModalService,
    public customerStateService: CustomerStateService
  ) { }
  
  async ngOnInit() {
    this.customerId = await firstValueFrom(this.route!.paramMap.pipe(map((params: ParamMap) => params.get('customerId')!)))

    var mobileNumber = sessionStorage.getItem("mobileNumber")
    if (mobileNumber != null)
    {
      this.customerStateService.setMobileNumber(mobileNumber)
    }

    // Edit account
    this.consumerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNumber: ['', [Validators.required, Validators.pattern('[- +()0-9]{10,12}')]],
      email: ['', Validators.required],

      street: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      stateOrProvince: ['', Validators.required],
      zip: ['', Validators.required],

      password: ['', Validators.minLength(8)],
      confirmPassword: ['', Validators.minLength(8)],
    });
    
    this.consumerForm.patchValue({
      mobileNumber: await firstValueFrom(this.customerStateService.mobileNumber)
    });
    
  }

  get consumerValue() { return this.consumerForm.controls; }

  get firstName() { return this.consumerForm.get('firstName'); }
  get lastName() { return this.consumerForm.get('lastName'); }
  get mobileNumber() { return this.consumerForm.get('mobileNumber'); }
  get email() { return this.consumerForm.get('email'); }

  get addressType() { return this.consumerForm.get('addressType'); }
  get street() { return this.consumerForm.get('street'); }
  get city() { return this.consumerForm.get('city'); }
  get country() { return this.consumerForm.get('country'); }
  get stateOrProvince() { return this.consumerForm.get('stateOrProvince'); }
  get zip() { return this.consumerForm.get('zip'); }

  get password() { return this.consumerForm.get('password'); }
  get confirmPassword() { return this.consumerForm.get('confirmPassword'); }


  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  createAccount() {
    return this.apiService.createAccount(this.customerStateService.customerId, this.consumerValue)
  }
  
  registerOnChange(onChange: any) {
    this.consumerForm.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(onChange);
  }

  registerOnTouched(onTouch: any) {
    this.consumerForm.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(onTouch);
  }

  validate() {
    return this.consumerForm.valid ? null : { invalidForm: 'Entry form is invalid' };
  }

  async savePersonalInfo() {
    if (this.consumerForm.value)
    {
        this.apiService.createAccount(this.customerId, <Partial<IConsumer>>this.consumerForm.value).pipe(first()).subscribe(c =>
          this.apiService.login(this.mobileNumber.value, this.password.value, this.customerId)
          .pipe(first()).subscribe( 
          consumer => {
            this.consumerForm.setErrors({ unauthenticated: false });
            this.consumerLogin = consumer;
            this.apiService.getConsumerDetails(consumer.consumer.customerId, this.mobileNumber.value).pipe(first()).subscribe(
              consumerDetails => {
                this.customerStateService.setCurrentConsumerDetails(consumerDetails);
              }
            )
            this.apiService.getCustomerDetails(consumer.consumer.customerId, consumer.token).pipe(first()).subscribe(
              customerDetails => {
                this.customerStateService.setCurrentCustomerDetails(customerDetails);
                // this.successModal = this.modalService.show(toOpen)
                this.router.navigate([`/customer/${this.customerStateService.customerId}/manage-my-membership/dashboard`])
              }
            )
          },
          error => {
            this.loading = false; 
            this.consumerForm.setErrors({ unauthenticated: true });
            this.consumerForm.controls['password'].setValue('');
            this.consumerForm.controls['confirmPassword'].setValue('');
            this.alertService.error(error);
          }
        )

        )

        
    }
  }
  // addAddresses(addressArray: Addresses[], i: number){
  //     const a = this.formBuilder.group({
  //       id: [addressArray[i].id, Validators.required], 
  //       consumerId: [addressArray[i].consumerId, Validators.required],
  //       addressType: [addressArray[i].addressType, Validators.required],
  //       street: [addressArray[i].street, Validators.required],
  //       city: [addressArray[i].city, Validators.required],
  //       country: [addressArray[i].country, Validators.required],
  //       stateOrProvince: [addressArray[i].stateOrProvince, Validators.required],
  //       zip: [addressArray[i].zip, Validators.required],
  //     })
  //     this.addresses.push(a)
  // }
}
