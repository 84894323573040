import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { prettyPhoneNumber, propertyOf } from 'src/app/utils/string-helpers';
import { environment } from 'src/environments/environment';
import { ICustomerPassWithSiteProducts } from 'src/models/customer-pass';
import { IPromo } from 'src/models/promo';
import { IPassPaymentAdditionalInfo, WNAVSResponses, WNCVVResponses, WNPaymentResponse, WNTransactionResponses } from 'src/models/worldnet-models';
import { PassPurchaseService } from '../pass-purchase.service';
import { CustomerInformation } from 'src/models/customer';
import { CustomerStateService } from 'src/services/customer-state.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { formatDate } from '@angular/common';

@Component({
    selector: 'dc-pass-receipt',
    templateUrl: './pass-receipt.component.html',
    styleUrls: ['./pass-receipt.component.scss']
})
export class PassReceiptComponent implements OnInit, OnDestroy {

    public promo$: Observable<IPromo>;
    public customerInformation$: Observable<CustomerInformation> = null;
    public customerPass: ICustomerPassWithSiteProducts;
    public promo: IPromo;

    public thankYouMessage: string = null;
    public thankYouUrl: string = null;

    public fromName: string;
    public toName: string;
    public passName: string;
    public promoName: string;
    public phoneNumber: string;
    public recipientPhoneNumber: string;
    public email: string;
    public monthlyPrice: number;
    public numberOfMonths: number;
    public activationDate: string;
    public amountCharged: number;
    public taxPercent: number;
    public surchargePercent: number;
    public taxValue: number;
    public surchargeValue: number;
    public responseCode: WNTransactionResponses;
    public responseText: string;

    public primaryTextColor: string;
    public secondaryTextColor: string;
    public primaryColor: string;
    public neutralBgColor: string;
    public secondaryBgColor: string;
    public additionalProperty1: string;
    public additionalProperty2: string;
    public today = new Date();

    
    public instagramLink: string;
    public facebookLink: string;
    public youtubeLink: string;
    public mobileDownload: string;
    
    public time = formatDate(this.today, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '-0400');


    public image: SafeResourceUrl;
    public backgroundImage: string;

    private readonly onDestroy$ = new Subject<void>();

    constructor(
        public customerStateService: CustomerStateService,
        private passPurchaseService: PassPurchaseService,
        public sanitizer:DomSanitizer,
        private route: ActivatedRoute,
        private host: ElementRef<HTMLElement>,
        private router: Router
    )  {}

    async ngOnInit() {
        const customerId$ = this.route.parent.params.pipe(
            map(t => t.customerId),
          );

        const favoredSiteId$ = this.route.params.pipe(
            map(t => t.siteId),
        );

        customerId$.pipe(takeUntil(this.onDestroy$)).subscribe(customerId => this.customerStateService.setCurrentCustomerId(customerId));
        favoredSiteId$.pipe(takeUntil(this.onDestroy$)).subscribe(siteId => this.customerStateService.forceSetCurrentActiveSite(siteId));
        // favoredSiteId$.pipe(takeUntil(this.onDestroy$)).subscribe(favoredSiteId => this.passPurchaseService.siteId$ = favoredSiteId);
          

        this.passPurchaseService.initData();

        this.route.queryParamMap.pipe(takeUntil(this.onDestroy$)).subscribe(
            paramMap => {
                const orderIdPropName: keyof WNPaymentResponse = 'ORDERID';
                const responseCodePropName: keyof WNPaymentResponse = 'RESPONSECODE';
                if (paramMap.has(orderIdPropName) && paramMap.has(responseCodePropName)) {
                    // parameters look like a payment response
                    var paymentResponse: WNPaymentResponse = {
                        AMOUNT: parseFloat(paramMap.get(propertyOf<WNPaymentResponse>('AMOUNT'))) || null,
                        APPROVALCODE: paramMap.get(propertyOf<WNPaymentResponse>('APPROVALCODE')),
                        AVSRESPONSE: paramMap.get(propertyOf<WNPaymentResponse>('AVSRESPONSE')) as WNAVSResponses,
                        BRANDTXIDENTIFIER: paramMap.get(propertyOf<WNPaymentResponse>('BRANDTXIDENTIFIER')),
                        CARDEXPIRY: paramMap.get(propertyOf<WNPaymentResponse>('CARDEXPIRY')),
                        CARDNUMBER: paramMap.get(propertyOf<WNPaymentResponse>('CARDNUMBER')),
                        CARDREFERENCE: paramMap.get(propertyOf<WNPaymentResponse>('CARDREFERENCE')),
                        CARDTYPE: paramMap.get(propertyOf<WNPaymentResponse>('CARDTYPE')),
                        COUNTRY: paramMap.get(propertyOf<WNPaymentResponse>('COUNTRY')),
                        CUSTOMFIELD: paramMap.get(propertyOf<WNPaymentResponse>('CUSTOMFIELD')),
                        CVVRESPONSE: paramMap.get(propertyOf<WNPaymentResponse>('CVVRESPONSE')) as WNCVVResponses,
                        DATETIME: paramMap.get(propertyOf<WNPaymentResponse>('DATETIME')),
                        EMAIL: paramMap.get(propertyOf<WNPaymentResponse>('EMAIL')),
                        FRAUDREVIEWREASONCODE: paramMap.get(propertyOf<WNPaymentResponse>('FRAUDREVIEWREASONCODE')),
                        FRAUDREVIEWRISKRATING: paramMap.get(propertyOf<WNPaymentResponse>('FRAUDREVIEWRISKRATING')) as any,
                        FRAUDREVIEWSCORE: parseFloat(paramMap.get(propertyOf<WNPaymentResponse>('FRAUDREVIEWSCORE'))) || null,
                        FRAUDREVIEWSTATUS: paramMap.get(propertyOf<WNPaymentResponse>('FRAUDREVIEWSTATUS')) as any,
                        HASH: paramMap.get(propertyOf<WNPaymentResponse>('HASH')),
                        ISSTORED: paramMap.get(propertyOf<WNPaymentResponse>('ISSTORED')) as 'true'|'false',
                        MERCHANTREF: paramMap.get(propertyOf<WNPaymentResponse>('MERCHANTREF')),
                        ORDERID: paramMap.get(propertyOf<WNPaymentResponse>('ORDERID')),
                        OTHERFIELD: paramMap.get(propertyOf<WNPaymentResponse>('OTHERFIELD')),
                        PHONE: paramMap.get(propertyOf<WNPaymentResponse>('PHONE')),
                        RESPONSECODE: paramMap.get(propertyOf<WNPaymentResponse>('RESPONSECODE')) as WNTransactionResponses,
                        RESPONSETEXT: paramMap.get(propertyOf<WNPaymentResponse>('RESPONSETEXT')),
                        SCERROR: paramMap.get(propertyOf<WNPaymentResponse>('SCERROR')),
                        STOREDCREDENTIALTXTYPE: paramMap.get(propertyOf<WNPaymentResponse>('STOREDCREDENTIALTXTYPE')),
                        STOREDCREDENTIALUSE: paramMap.get(propertyOf<WNPaymentResponse>('STOREDCREDENTIALUSE')),
                        UNIQUEREF: paramMap.get(propertyOf<WNPaymentResponse>('UNIQUEREF')),
                        ACTIVATIONDATE: paramMap.get(propertyOf<WNPaymentResponse>('ACTIVATIONDATE')),
                        CONSUMERID: paramMap.get(propertyOf<WNPaymentResponse>('CONSUMERID')),
                        DURATION: paramMap.get(propertyOf<WNPaymentResponse>('DURATION')),
                        FROMNAME: paramMap.get(propertyOf<WNPaymentResponse>('FROMNAME')),
                        PASSCOST: paramMap.get(propertyOf<WNPaymentResponse>('PASSCOST')),
                        PASSID: paramMap.get(propertyOf<WNPaymentResponse>('PASSID')),
                        PROMOCODE: paramMap.get(propertyOf<WNPaymentResponse>('PROMOCODE')),
                        PROMONAME: paramMap.get(propertyOf<WNPaymentResponse>('PROMONAME')),
                        TAX: parseFloat(paramMap.get(propertyOf<WNPaymentResponse>('TAX'))),
                        SURCHARGE: parseFloat(paramMap.get(propertyOf<WNPaymentResponse>('SURCHARGE'))),
                        TONAME: paramMap.get(propertyOf<WNPaymentResponse>('TONAME')),
                        RECIPIENTPHONE: paramMap.get(propertyOf<WNPaymentResponse>('RECIPIENTPHONE'))
                    };
                    this.passPurchaseService.setWorldNetReceipt(paymentResponse);
                }
            }
        );

        combineLatest([
            this.passPurchaseService.purchaseDetails$.pipe(takeUntil(this.onDestroy$), first(t => t.receipt != null)),
            this.passPurchaseService.consumer$.pipe(takeUntil(this.onDestroy$), first(t => t != null)),
            this.customerStateService.favoredSite$.pipe(takeUntil(this.onDestroy$), first(t => t != null) ),
            this.customerStateService.currentCustomerInfo$.pipe(takeUntil(this.onDestroy$), first(t => t != null) )
        ]).subscribe(
                ([purchaseDetails, consumer, site, customer]) => {
                const receipt = purchaseDetails.receipt;//the additional information is stored in this somehow.
                const pass = purchaseDetails.customerPass;

                this.customerPass = pass;
                this.activationDate = (purchaseDetails.activationDate ? purchaseDetails.activationDate : new Date).toLocaleDateString();
                this.numberOfMonths = purchaseDetails.monthsDuration;

                this.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/svg;base64,' + customer.image);
                this.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/svg;base64,' + customer.image);
                this.backgroundImage = "url('data:image/svg;base64," + customer.backgroundImage + "')";


                this.facebookLink   = customer.facebookLink ?? null; 
                this.instagramLink  = customer.instagramLink ?? null;
                this.youtubeLink    = customer.youtubeLink ?? null;
                this.mobileDownload = customer.mobileDownload ?? null;
                
                    
                this.passName = pass ? pass.passName : null;
                this.monthlyPrice = purchaseDetails.monthlyPrice ? purchaseDetails.monthlyPrice : null;
                this.promoName = receipt.PROMONAME ? receipt.PROMONAME : null;
                if(this.promoName){
                    let response = this.passPurchaseService.promoRedeem(Number(receipt.PROMOCODE), purchaseDetails.phoneNumber, this.customerPass.customerId);
                }


                this.passPurchaseService.tax.subscribe(num => {
                    this.taxPercent = num
                    this.taxValue = num * this.monthlyPrice
                })
                this.passPurchaseService.surcharge.subscribe(num => {
                    this.surchargePercent = num
                    this.surchargeValue = num * this.monthlyPrice
                })

                this.phoneNumber = prettyPhoneNumber(purchaseDetails.phoneNumber);
                this.recipientPhoneNumber = prettyPhoneNumber(purchaseDetails.recipientPhoneNumber);
                this.email = purchaseDetails.emailAddress;
                this.fromName = purchaseDetails.fromName;
                this.toName = purchaseDetails.toName;
                this.responseCode = receipt.RESPONSECODE;
                this.responseText = receipt.RESPONSETEXT;
                this.amountCharged = receipt.AMOUNT;
                
                if (receipt.RESPONSECODE === WNTransactionResponses.APPROVED) {
                    // TODO post to the API to make the pass and record the transaction
                    if( this.email === "")
                    {
                        this.passPurchaseService.postPassPurchase(site.id, receipt, consumer.id, pass.id, customer.mobileDownload, customer.thankYouUrl)
                    }
                    else
                    {
                        this.passPurchaseService.postGiftPassPurchase(site.id, receipt, consumer.id, pass.id, customer.mobileDownload)
                    }
                }
            }
        );
    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }

    return(): void {
        this.passPurchaseService.resetModel();
        var customerId = sessionStorage.getItem('customerId')
        this.router.navigate(["customer", customerId]);
    }
    
    toFixedIfNecessary( value, dp ){
        return +parseFloat(value).toFixed( dp );
    }
}
