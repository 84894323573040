import { Injectable, OnChanges } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, filter, first, firstValueFrom, forkJoin, from, map, of, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs';
import { CustomerInformation } from 'src/models/customer';
import { ApiService } from './api.service';
import { SiteInformation } from 'src/models/site-information';
import { ModalSiteInfo } from 'src/models/site-information';
import { LocationInfo } from 'src/models/location-info';
import { ConsumerDetails } from 'src/models/consumer-details';
import { CustomerDetails } from 'src/models/customer-details';
import { ISiteProduct } from 'src/models/site-product';
import { ICustomerPassWithSiteProducts } from 'src/models/customer-pass';
import { Payment } from 'src/models/payment';

@Injectable({
  providedIn: 'root'
})
export class CustomerStateService{

  private __siteSubject$ = new BehaviorSubject<string | null>(sessionStorage.getItem('siteId'));

  private _siteSubject$: ReplaySubject<string> = new ReplaySubject<string>(1);
  public favoredSiteId$ = this._siteSubject$.asObservable();
  //public get siteId(): string | null { return this._siteSubject$.getValue(); }

  public get siteId(): string | null { 
    var siteid = ""
    this._siteSubject$.subscribe(id => siteid = id);
    return siteid;
  }
  public set siteId(item: string | null) { this._siteSubject$.next(item); }


  private currentConsumerDetailsSubject$ = new BehaviorSubject<ConsumerDetails|null>(JSON.parse(sessionStorage.getItem('ConsumerDetails')));
  public currentConsumerDetails$ = this.currentConsumerDetailsSubject$.asObservable();
  public get consumerDetails(): ConsumerDetails | null { return this.currentConsumerDetailsSubject$.getValue(); }
  public set consumerDetails(item: ConsumerDetails | null) { this.currentConsumerDetailsSubject$.next(item); }

  private currentCustomerDetailsSubject$ = new BehaviorSubject<CustomerDetails|null>(JSON.parse(sessionStorage.getItem('CustomerDetails')));
  public currentCustomerDetails$ = this.currentCustomerDetailsSubject$.asObservable();
  public get customerDetails(): CustomerDetails | null { return this.currentCustomerDetailsSubject$.getValue(); }
  public set customerDetails(item: CustomerDetails | null) { this.currentCustomerDetailsSubject$.next(item); }

  private _currentCustomerIdSubject$ = new ReplaySubject<string|null>();
  // private currentCustomerIdSubject$ = new BehaviorSubject<string|null>(sessionStorage.getItem('customerId'));
  // public currentCustomerId$ = this.currentCustomerIdSubject$.asObservable();

  get currentCustomerId$() : Observable<string> {
    return this._currentCustomerIdSubject$.asObservable();
  }

  public get customerId(): string | null { 
    var customerid = ""
    this._currentCustomerIdSubject$.subscribe(id => customerid = id);
    return customerid;
  }
  public set customerId(item: string | null) { this._currentCustomerIdSubject$.next(item); }

  private customerPassSubject = new ReplaySubject<ICustomerPassWithSiteProducts[]>(null)
  public customerPass$ = this.customerPassSubject.asObservable();
  public get customerPass() {return this.customerPassSubject.asObservable();}

  private purchaseHistorySubject = new ReplaySubject<Payment[]>(null)
  public purchaseHistory$ = this.purchaseHistorySubject.asObservable();
  public get purchaseHistory() {return this.purchaseHistorySubject.asObservable();}
  
  private mobileNumberSubject = new ReplaySubject<string>(null)
  public mobileNumber$ = this.mobileNumberSubject.asObservable();
  public get mobileNumber() {return this.mobileNumberSubject.asObservable();}

  private mapResponseSubject$ = new BehaviorSubject<LocationInfo | null>(null);
  public mapResponse$ = this.mapResponseSubject$.asObservable();

  public currentCustomerInfo$: Observable<CustomerInformation|null>;

  public sites$: Observable<SiteInformation[]>

  public modalSites$: Observable<ModalSiteInfo[] | null>;

  public favoredSite$ = new BehaviorSubject<SiteInformation | null> (null);


  public BRONZE = "6db6b50e-f031-4fa8-b706-08df3ad5cd9f";
  public SILVER = "170c2796-cb17-4fcb-87e5-d9f778df1cc0";
  public GOLD = "227316fa-af47-4811-8992-949be9c35fe3";
  public PLATINUM = "bace3dcf-4f01-44f7-96dd-cd1e80c2952c";

  constructor(private apiService: ApiService) {
    this.currentCustomerInfo$ = this._currentCustomerIdSubject$.pipe(
        switchMap( customerId => !!customerId ?  this.apiService.getCustomerInformation(customerId) : of(null)),
        shareReplay(1)
    );
    // this.currentCustomerInfo$ = this.currentCustomerId$.pipe(
    //     switchMap( customerId => !!customerId ?  this.apiService.getCustomerInformation(customerId) : of(null)),
    //     shareReplay(1)
    // );

    this.currentCustomerInfo$.subscribe(c => {
      this.getfavoredSite(c.id).then(t => {
        this.favoredSite$.next(t);
      })
    })
    

    this.sites$ = this._currentCustomerIdSubject$.pipe(
      switchMap(customerId => !!customerId ? this.apiService.getCustomerSites(customerId) : of(null)),
      tap(t => console.log(t)),
      shareReplay(1)
    );

    this.modalSites$ = this.mapResponse$.pipe(
      filter(t => t != null),
      switchMap(response => response != null ? this.apiService.getModalSites(response.lat, response.lon ) : of (null)),
      shareReplay(1)
    );
  }

  async getCurrentCustomerInfo() {
    var customerId = sessionStorage.getItem("customerId");
    return await firstValueFrom(this.apiService.getCustomerInformation(customerId))
  }

  async getfavoredSite(customerId: string) {
    
    var siteInformation: SiteInformation;
    var siteId = sessionStorage.getItem("siteId");

    var sites = await firstValueFrom(this.apiService.getCustomerSites(customerId))
    var customer = await firstValueFrom(this.apiService.getCustomerInformation(customerId))


    if (siteId != null && siteId !== "")
    {
      siteInformation = sites.find(t => t.id === siteId)
    }
    else 
    {
      if (customer.favoredSite != null && customer.favoredSite !== "")
      {
        siteInformation = sites.find(t => t.id === customer.favoredSite) ?? null
      }
      else
      {
        siteInformation = sites[0]
      }
    }
    this.siteId = siteInformation.id;
    
    return siteInformation;
  }
  setCurrentConsumerDetails(consumerDetails: ConsumerDetails) {
      sessionStorage.setItem("consumerDetails", JSON.stringify(consumerDetails));
      this.currentConsumerDetailsSubject$.next(consumerDetails);
  }
  
  setCurrentCustomerDetails(customerDetails: CustomerDetails) {
    sessionStorage.setItem("customerDetails", JSON.stringify(customerDetails));
    this.currentCustomerDetailsSubject$.next(customerDetails);
}
  pushCustomerId(customerId: string) {
    sessionStorage.setItem("customerId", customerId);
    this._currentCustomerIdSubject$.next(customerId);
  } 

  setCurrentCustomerId(customerId: string|null) {
      sessionStorage.setItem("customerId", customerId);
      // this.currentCustomerIdSubject$.next(customerId);
      this.pushCustomerId(customerId);
  }


  get siteSubject$() : Observable<string> {
    return this._siteSubject$.asObservable();
  }

  pushSiteId(siteId: string) {
    this._siteSubject$.next(siteId);
  } 

  // setCurrentConsumerId(consumerId: string|null) {
  //     sessionStorage.setItem("consumerId", consumerId);
  //     this.currentConsumerIdSubject$.next(consumerId);
  // }

  async setCurrentSite(paramCustomerId?: string) {
      var siteId = sessionStorage.getItem("siteId");
      var siteInformation: SiteInformation;
     
      if (paramCustomerId != null) {
      

        forkJoin([
          this.currentCustomerInfo$ = await this.apiService.getCustomerInformation(paramCustomerId).pipe(first()),
          this.sites$ = await this.apiService.getCustomerSites(paramCustomerId).pipe(first())
        ])
        .pipe(first()).subscribe(([c, s]) => {
          
          if (siteId)
            {
              siteInformation = s.find(t => t.id === siteId)
            }
            else 
            {
              siteInformation = s.find(t => t.id === c.favoredSite) ?? null
            }
            this.siteId = siteInformation.id
          
            this.favoredSite$.next(siteInformation);
        })
        
      }
      else {
        this.currentCustomerInfo$ = this.currentCustomerId$.pipe(
          switchMap(customerId => !!customerId ? this.apiService.getCustomerInformation(paramCustomerId) : of(null)),
          shareReplay(1)
        );
        this.sites$ = this.currentCustomerId$.pipe(
          switchMap(customerId => !!customerId ? this.apiService.getCustomerSites(customerId) : of(null)),
          shareReplay(1)
        );
        combineLatest([this.currentCustomerInfo$, this.sites$], (customer, sites) => {
          if (!siteId || siteId !== "")
          {
            siteInformation = sites.find(t => t.id === siteId)
          }
          else 
          {
            siteInformation = sites.find(t => t.id === customer.favoredSite) ?? null
          }
          this.siteId = siteInformation.id
          this.favoredSite$.next(siteInformation); 
        })
      }
  }

  setMapResponse(response: LocationInfo | null) {
    this.mapResponseSubject$.next(response);
  }
  
  forceSetCurrentActiveSite(siteId: string | null){
    sessionStorage.setItem("siteId", siteId);
    this.siteId = siteId
    this.pushSiteId(siteId);
     this.sites$.pipe(
      map( sites => { 
        this.favoredSite$.next( sites.find(newActiveSite => newActiveSite.id === siteId))
    }));
  }

  async checkSite(customerId: string) {

    var siteId = sessionStorage.getItem("siteId");

    if (!siteId || siteId === "" || customerId === "undefined")
    {
      var customer = await firstValueFrom(this.apiService.getCustomerInformation(customerId))
      sessionStorage.setItem("siteId", customer.favoredSite);
      this.siteId = customer.favoredSite;
    }
      
  }

  setPassHeaderInfo(customerPasses: any[], siteProducts: ISiteProduct[]) {
    var cpwsp = new Array<ICustomerPassWithSiteProducts>(...customerPasses as any);
    for (let i = cpwsp.length - 1; i >= 0; i--) {
        const productTemplateIds = cpwsp[i].products.map(p => p.productTemplateId);
        cpwsp[i].siteProducts = siteProducts.filter(sp => productTemplateIds.includes(sp.productTemplateId)  && ((sp.productTemplateId == this.PLATINUM || sp.productTemplateId == this.GOLD || sp.productTemplateId == this.SILVER || sp.productTemplateId == this.BRONZE)));
    }
    // get all the unique product description picture keys in order that they appear
    // this will act as the "master list" of product descriptions
    var allPictureKeys = cpwsp.flatMap(t => t.siteProducts).flatMap(t => t.productDescriptions).map(t => t.pictureKey);
    var distinctPictureKeys = new Array<string>(...(new Set(allPictureKeys)));
    
    // Populate the product description array on each site product of each customer pass with dummy descriptions
    // to ensure that each site product contains all of the used picture keys. The dummy descriptions have null
    // `id` fields. This is to ensure that all of the product description images can be displayed by each
    // product, with the unused descriptions shown as disabled or unincluded.
    // This logic assumes that no picture key is used multiple times within a given product, and that picture
    // keys that are used across multiple products appear in the same order.
    for (let i = cpwsp.length - 1; i >= 0; i--) {
        const customerPass = cpwsp[i];
        for (let j = customerPass.siteProducts.length - 1; j >= 0; j--) {
            const siteProduct = customerPass.siteProducts[j];
            
            const pictureKeys = siteProduct.productDescriptions.map(t => t.pictureKey);
            for (let k = 0; k < distinctPictureKeys.length; k++) {
                const pictureKey = distinctPictureKeys[k];
                //This is a temporary fix to keep product descriptions from showing on products that arent gold silver or bronze
                //eventually will need to have a different push
                if(siteProduct.productTemplateId == this.PLATINUM || siteProduct.productTemplateId == this.GOLD || siteProduct.productTemplateId == this.SILVER || siteProduct.productTemplateId == this.BRONZE){
                    if (!pictureKeys.includes(pictureKey)) {
                        siteProduct.productDescriptions.push({
                            id: null,
                            description: null,
                            pictureKey: pictureKey,
                            descriptionImage: null,
                            descriptionOrder: k,
                            siteProductId: siteProduct.id
                        });
                    }
                }
            }
        }
    }
    
    this.customerPassSubject.next(cpwsp)
  }

  setPurchaseHistory(payments: Payment[]) {
    
    this.purchaseHistorySubject.next(payments)
  }

  setMobileNumber(mobileNumber: string) {
    sessionStorage.setItem("mobileNumber", mobileNumber)
    this.mobileNumberSubject.next(mobileNumber)
  }
}
