import { ElementRef, EventEmitter, Output } from '@angular/core';
import { Component, SimpleChanges, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';

@Component({
    selector: 'dencar-static-html',
    templateUrl: './static-html.component.html',
    styleUrls: ['./static-html.component.scss']
})
export class StaticHtmlComponent {

    @ViewChild('article', { static: true }) article: ElementRef<HTMLElement>;

    innerHtml: SafeHtml;
    isLoading: boolean = false;

    @Input() htmlString: string;
    @Output() loaded = new EventEmitter<void>();

    constructor(private domSanitizer: DomSanitizer,
        private appSettingsService: AppSettingsService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.htmlString) {
            this.replaceHtml(this.htmlString);
        }
    }


    private replaceHtml(htmlString: string): void {
        this.isLoading = true;

        htmlString = htmlString.replace('action="payment"', `action="${this.appSettingsService.worldnetUrl}"`);

        this.innerHtml = this.domSanitizer.bypassSecurityTrustHtml(htmlString);

        const temp = document.createElement('article');
        temp.innerHTML = htmlString;

        const oldScripts = Array.from(temp.querySelectorAll('script'));
        const newScriptsNeedLoading = new Array<HTMLScriptElement>();   // <script> tags that need to load from a [src]
        const newScriptsImmediate = new Array<HTMLScriptElement>();     // <script> tags that do not need to load from a [src] and will execute immediately

        const onScriptLoaded = (event: Event) => {
            const target = event.target as HTMLScriptElement;
            const index = newScriptsNeedLoading.indexOf(target);
            if (index >= 0) {
                newScriptsNeedLoading.splice(index, 1);
                if (newScriptsNeedLoading.length === 0) {
                    this.isLoading = false;
                    newScriptsImmediate.forEach(t => this.article.nativeElement.appendChild(t));
                    this.loaded.emit();
                }
            }
        };

        for (let i = 0; i < oldScripts.length; i++) {
            const oldScript = oldScripts[i];
            const newScript = document.createElement('script');
            const oldScriptAttributes = Array.from(oldScript.attributes);
            for (let j = 0; j < oldScriptAttributes.length; j++) {
                const attr = oldScriptAttributes[j];
                newScript.setAttribute(attr.name, attr.value);
            }
            newScript.appendChild(document.createTextNode(oldScript.innerHTML));

            if (newScript.hasAttribute('src')) {
                newScript.addEventListener('load', onScriptLoaded);
                newScript.addEventListener('error', onScriptLoaded);
                newScriptsNeedLoading.push(newScript);
            } else {
                newScriptsImmediate.push(newScript);
            }
        }

        if (newScriptsNeedLoading.length === 0) {
            this.isLoading = false;
            newScriptsImmediate.forEach(t => this.article.nativeElement.appendChild(t));
            this.loaded.emit();
        } else {
            newScriptsNeedLoading.forEach(t => this.article.nativeElement.appendChild(t));
        }

    }
}