import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { shareReplay, tap } from 'rxjs/operators';
import { AppSettings } from './app-settings';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  private httpClient: HttpClient;

  private appSettings: AppSettings;
  public appSettings$: Observable<AppSettings>;

  constructor(handler: HttpBackend) {
    this.appSettings = { apiUrl: null!, apiVersion: null!, environment: null!, wesbiteUrl: null!, googleMapsKey: null!, isProduction: null!, worldnetUrl: null! };
    this.httpClient = new HttpClient(handler);
    this.appSettings$ = this.httpClient.get<AppSettings>('/config/settings.json').pipe(
      shareReplay(1),
      tap(appSettings => this.appSettings = appSettings)
    );
  }

  async initialize() {
    return await firstValueFrom(this.appSettings$);
  }

  get apiVersion() { return this.appSettings.apiVersion; }
  get apiUrl() { return this.appSettings.apiUrl }
  get websiteUrl() { return this.appSettings.wesbiteUrl }
  get googleMapsKey() { return this.appSettings.googleMapsKey }
  get environment() { return this.appSettings.environment; }
  get isProduction() { return this.appSettings.isProduction; }
  get worldnetUrl() { return this.appSettings.worldnetUrl; }

}
