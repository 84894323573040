// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*Command for launching ngrok to properly host locally */
//   ngrok http 4200 --host-header="localhost:4200"


/** URL for the dev API */
const devApi = 'https://api.dev.dencar.sancsoft.net';
/** URL for the staging API */
const stagingApi = 'https://api.staging.dencar.sancsoft.net';

/** UID for the State Road location in the dev database  */
const devSiteId = '5672cea2-6755-4d4b-a469-6773e07ff883';
/** UID for the Steels Corner Road location in the staging database */
const stagingSiteId = '1cf1dfe4-60c0-4d31-aa12-b0cceefb1198';

/** UID for the United Auto Wash customer in the dev database */
const devCustomerId = 'dfcfafe7-5d4e-4da1-9386-30b01bdb8aa5';
/** UID for the United Auto Wash customer in the staging database */
const stagingCustomerId = 'dfcfafe7-5d4e-4da1-9386-30b01bdb8aa5';     // the same UID as in the dev database

// const devPaymentId = 'b169ffb6-6515-4030-ae9a-6b84c54ff9fc';
// const devPaymentId2 = '6a5d592c-9577-451a-9cde-606497fabdde';
// const devPaymentId3 = 'b169ffb6-6515-4030-ae9a-6b84c54ff9fc';
// const devPaymentId4 = '462fd489-b2cc-48a3-8b17-6a1396d40800';
// const devPaymentId5 = '7482560c-05de-4adc-aef6-0fdfc8c25951';

export const environment = {
  production: false,
  // websiteUrl: 'https://66ef-38-94-192-82.ngrok.io',
  // apiUrl: devApi,
  // siteId: devSiteId,
  // customerName: 'United Auto Wash - none production',
  // customerId: devCustomerId,
  // apiVersion: 'v3.3',
  // thankYouMessage: 'Thank you for your purchase. Please follow this link to manage your monthly wash pass.',
  thankYouUrl: 'https://unitedautowash.com/mobile',
  // facebookPixel: '2i7uxe9fnh657h62ebcrgj54gz646b'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
