import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {
  public leftArrow: SafeResourceUrl;

  constructor(
    public sanitizer:DomSanitizer,
    private location: Location
  ){ }


  async ngOnInit () {
    
    this.leftArrow = this.sanitizer.bypassSecurityTrustResourceUrl('./assets/ManageMyMembership/right_arrow.svg');
  }

  goBackToPrevPage(): void {
    this.location.back();
  }
}
