export interface WNPaymentResponse {
    /** Same as informed at the transaction's request. */
    ORDERID: string;
    /** Amount of money charged in the transaction. */
    AMOUNT?: number;
    /** A six digit AuthCode. */
    APPROVALCODE: string;
    /** Indicates if the payment was accepted, declined, or some other result. */
    RESPONSECODE: WNTransactionResponses;
    /** The text of the authorization. */
    RESPONSETEXT: string;
    /** The time of the transaction created by the bank. Format: YYYY-MM-DDTHH:MM:SS. */
    DATETIME: string;
    /** The result of the AVS check. */
    AVSRESPONSE: WNAVSResponses;
    /** The result of tthe CVV check. */
    CVVRESPONSE: WNCVVResponses;
    /** Generated reference that should be stored for tracking and remote XML refunding. */
    UNIQUEREF: string;
    /** Card type used for the transaction. */
    CARDTYPE: string;
    /** The card number (obfuscated) that was used for the transaction. */
    CARDNUMBER: string;
    /** A hash code formed by part of the response fields. */
    HASH: string;
    FRAUDREVIEWSTATUS: 'PASS' | 'REVIEW' | 'REJECT';
    FRAUDREVIEWRISKRATING: 'HIGH' | 'MEDIUM' | 'LOW' | 'NEUTRAL' | 'TRUST';
    /** Number value, between -100 (highest risk) and +100 (lowest risk). */
    FRAUDREVIEWSCORE: number;
    /** Empty string, or a list of comma separated reasons of why this transaction is a risk. */
    FRAUDREVIEWREASONCODE: string;
    /** Same as informed at the transaction's request. Returns all custom fields informed on request. */
    CUSTOMFIELD?: string;
    /** Same as informed at the transaction's request. Returns all additional fields informed on request. */
    OTHERFIELD?: string;
    /** Same as informed at the transaction's request. Returned if informed on request. */
    EMAIL?: string;
    /** Same as informed at the transaction's request. Returned if informed on request. */
    PHONE?: string;
    /** Same as informed at the transaction's request. Returned if informed on request. */
    COUNTRY?: string;
    /** Same as informed at the transaction's request. Returned if informed on request. */
    BRANDTXIDENTIFIER?: string;
    /** Same as informed at the transaction's request. Returned if informed on request. */
    STOREDCREDENTIALUSE?: string;
    /** Same as informed at the transaction's request. Returned if informed on request. */
    STOREDCREDENTIALTXTYPE?: string;
    /** Same as informed at the transaction's request. Returned if informed on request. */
    ADDITIONALINFO?: string;
    /** Indicates if the card info was successfully stored. Only included if the card info was to be stored. */
    ISSTORED?: 'true' | 'false'
    /** Description of storage error if ISSTORED is 'false'. */
    SCERROR?: string;
    /** Original SECURECARDMERCHANTREF provided by the merchant on request. */
    MERCHANTREF?: string;
    /** Generated card reference. */
    CARDREFERENCE?: string;
    /** Expiry date of the card, formatted as MMYY. */
    CARDEXPIRY?: string;
    ACTIVATIONDATE?: string;
    CONSUMERID?: string;
    DURATION?: string;
    FROMNAME?: string;
    PASSCOST?: string;
    PASSID?: string;
    PROMOCODE?: string;
    PROMONAME?: string;
    TONAME?: string;
    RECIPIENTPHONE?: string;
    /** Tax value on receipt */
    TAX?: number;
    /** Surcharge value on receipt */
    SURCHARGE?: number;
}

export enum WNTransactionResponses {
    /** APPROVED / AUTHORIZED / ACCEPTED, respectively. */
    APPROVED = 'A',
    /** ACCEPTED for later processing, but result currently unknown - specifically for China Union Pay. */
    ACCEPTED = 'E',
    /** DECLINED. */
    DECLINED = 'D',
    /** REFERREED, also considered as PICKUP */
    REFERRED = 'R',
    /** PICKUP, also known as Referral A or Referral B */
    PICKUP = 'C'
}


/** AVS responses are for indication to the merchant only and usually they do not
 * influence the overall authorisation result. This can vary per the cardholders
 * bank though (i.e. the issuing bank). Note that transactions can still be
 * authorised, even if the AVS response is no match or if there is a failed response. */
export enum WNAVSResponses {
    /** Address matches, ZIP does not. The first five numerical characters contained
     * in the address match with those stored at the VIC or issuer’s center. However,
     * the zip code does not match. */
    AddressMatch = 'A',
    /** Ineligible transaction. */
    Ineligible = 'E',
    /** Neither address nor ZIP matches. Neither the first five numerical characters
     * contained in the address match with those stored at the VIC nor the issuer’s
     * center, nor the zip code match. */
    NoMatch = 'N',
    /** Retry. The system is unavailable or timed out. */
    Retry = 'R',
    /** Card type not supported. The card type for this transaction is not supported
     * by AVS. AVS can verify addresses for Visa cards, MasterCard, proprietary cards,
     * and private label transactions. */
    CardNotSupported = 'S',
    /** Address information unavailable */
    Unavailable = 'U',
    /** Address information unavailable, International - Visa only. The address
     * information was not available at the VIC or the issuer's center.
     */
    UnavailableInternational = 'G',
    /** Nine-digit zip match, address does not match. The nine-digit Postal zip code
     * matches that stored at the VIC or the card issuer's center. However, the first
     * five numerical characters contained in the address do not match. */
    ZipMatch = 'W',
    /** Both the nine-digit zip and postal code match. */
    ExactMatch = 'X'
}

/** CVV responses are for indication to the merchant only, and they usually do not
 * influence the overall authorisation result. This can vary per the cardholder's
 * bank, though (i.e. the issuing bank). Note that transactions can still be
 * authorised even if the CVV response is no match or if there is a failed response. */
export enum WNCVVResponses {
    Match = 'M',
    NoMatch = 'N',
    NotProcessed = 'P',
    /** CVV should be on the card but the merchant indicates it is not. */
    NoCVV = 'S',
    UserUnregistered = 'U'
}

/** This is the data passed into the "ADDITIONALINFO" input of the WorldNet hosted payment
 * page. This data is then returned to us by WorldNet so we can properly reinitialize 
 * Angular's runtime data.
 */
export interface IPassPaymentAdditionalInfo {
    consumerId: string;
    passId: string;
    passCost: string;
    duration: string;
    phone: string;
    recipientPhone: string;
    email: string;
    activationDate: string;
    fromName: string;
    toName: string;
    promoCode: string;
    customerId: string;
    promoName: string;
}