import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PassPurchaseService } from 'src/app/pass-purchase/pass-purchase.service';
import { ISiteProduct } from 'src/models/site-product';

@Component({
    selector: 'dencar-buy-this-monthly-pass',
    templateUrl: './buy-this-monthly-pass.svg.component.html',
    styleUrls: ['./buy-this-monthly-pass.svg.component.scss']
})
export class BuyThisMonthlyPassSvgComponent implements OnInit {
    @Input() disabled: boolean;
    public tier: string;
    @Input() products: ISiteProduct[];
    @Output() click = new EventEmitter<MouseEvent>();
    
    constructor(
        private passPurchaseService: PassPurchaseService){
    }
    
    ngOnInit(): void {
        if(this.products.find(t => t.productTemplateId === this.passPurchaseService.platinum)) { this.tier = 'platinum'; }
        else if(this.products.find(t => t.productTemplateId === this.passPurchaseService.gold)) { this.tier = 'gold'; }
        else if(this.products.find(t => t.productTemplateId === this.passPurchaseService.silver)) { this.tier = 'silver'; } 
        else if (this.products.find(t => t.productTemplateId === this.passPurchaseService.bronze)) { this.tier = 'bronze'; }    }

    public onClick(event: MouseEvent) {
        if (!this.disabled) {
            this.click.emit(event);
        }
    }

}