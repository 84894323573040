import { Component, OnDestroy, OnInit, Pipe } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, ParamMap } from '@angular/router';
import { map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CustomerInformation } from 'src/models/customer';
import { TextReceiptInformation } from 'src/models/text-receipt-information';
import { ApiService } from 'src/services/api.service';
import { Observable, Subject } from 'rxjs';
import { CustomerStateService } from 'src/services/customer-state.service';
import { AppSettingsService } from './app-settings/app-settings.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare let fbq:Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy{

    private onDestroy$ = new Subject<void>();
    public isProduction: boolean;

    public backgroundImageExists: boolean = false;
    public backgroundImage: SafeResourceUrl;

    favIcon: HTMLLinkElement = document.querySelector('#appIcon');
    constructor(
        public router: Router,
        private route: ActivatedRoute, 
        private apiService: ApiService,
        public sanitizer:DomSanitizer,
        public customerStateService: CustomerStateService,
        public appSettingsService: AppSettingsService
    ) {
        this.route.params.subscribe();
        this.isProduction = this.appSettingsService.isProduction;
    }
    
    async ngOnInit() {
        this.customerStateService.currentCustomerInfo$.subscribe( customerDashboard => {
            document.documentElement.style.setProperty('--theme-primary-color', "#" + customerDashboard.primaryColor);
            document.documentElement.style.setProperty('--theme-primary-text-color', "#" + customerDashboard.primaryTextColor);
            document.documentElement.style.setProperty('--theme-neutral-bg-color', "#" + customerDashboard.neutralBgColor);
            document.documentElement.style.setProperty('--theme-secondary-text-color', "#" + customerDashboard.secondaryTextColor);
            document.documentElement.style.setProperty('--theme-secondary-bg-color', "#" + customerDashboard.secondaryBgColor);
            document.documentElement.style.setProperty('--theme-tier-4-banner-color', "#" + customerDashboard.tier4Banner);
            document.documentElement.style.setProperty('--theme-tier-3-banner-color', "#" + customerDashboard.tier3Banner);
            document.documentElement.style.setProperty('--theme-tier-2-banner-color', "#" + customerDashboard.tier2Banner);
            document.documentElement.style.setProperty('--theme-tier-1-banner-color', "#" + customerDashboard.tier1Banner);
        });
        this.customerStateService.currentCustomerInfo$.pipe(takeUntil(this.onDestroy$)).subscribe( customerDashboard =>{
            if(customerDashboard)
            {
 
                // populate the customer header background image and logo(image)
                if(customerDashboard.backgroundImage)
                {
                    this.backgroundImage = this.sanitizer.bypassSecurityTrustResourceUrl(`url('data:image/svg;base64,${customerDashboard.backgroundImage}')`);
                    this.backgroundImageExists = true;       
                }
                else {
                    this.backgroundImage = null;
                }
            }
        });

    }
    
    async ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
  }
}
