import { Component, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, first, map, switchMap } from 'rxjs';
import { IConsumer } from 'src/models/consumer';
import { ConsumerLogin } from 'src/models/consumer-login';
import { CustomerInformation } from 'src/models/customer';
import { AlertService } from 'src/services/alert.service';
import { ApiService } from 'src/services/api.service';
import { CustomerStateService } from 'src/services/customer-state.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  modalRef?: BsModalRef;
  phoneNumber!: FormGroup;
  loginForm!: FormGroup;
  loading = false;
  submitted = false;

  public customers: CustomerInformation[] = null;
  public consumerLogin: ConsumerLogin;
  public consumer: IConsumer;
  public customerId$: Observable<string>;
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private alertService: AlertService,
    private modalService: BsModalService,
    public customerStateService: CustomerStateService
  ) { 
    document.body.style.overflow = 'hidden'
  }
  
  ngOnInit() {
    
    this.customerId$ = this.customerStateService.currentCustomerId$
    this.loginForm = this.formBuilder.group({
      customerId: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.pattern('[- +()0-9]{10,12}')]],
      password: ['', Validators.required]
    });
    this.phoneNumber = this.formBuilder.group({
        phoneNumber: ['', [Validators.required, Validators.pattern('[- +()0-9]{10,12}')]],
    });
  }

  get numberValue() { return this.phoneNumber.controls; }
  get loginValue() { return this.loginForm.controls; }

  onLogin() {
    
    this.customerId$.pipe(switchMap((customerId: string) => 
      this.apiService.login(this.loginValue.phoneNumber.value, this.loginValue.password.value, customerId)))
        .pipe(first()).subscribe( 
        consumer => {
          this.loginForm.setErrors({ unauthenticated: false });
          this.consumerLogin = consumer;
          this.apiService.getConsumerDetails(consumer.consumer.customerId, this.loginValue.phoneNumber.value).pipe(first()).subscribe(
            consumerDetails => {
              this.customerStateService.setCurrentConsumerDetails(consumerDetails);
            }
          )
          this.apiService.getCustomerDetails(consumer.consumer.customerId, consumer.token).pipe(first()).subscribe(
            customerDetails => {
              this.customerStateService.setCurrentCustomerDetails(customerDetails);
              // this.successModal = this.modalService.show(toOpen)
              this.router.navigate([`/customer/${this.customerStateService.customerId}/manage-my-membership/dashboard`])
            }
          )
          
          this.router.navigate([`/customer/${this.customerStateService.customerId}/manage-my-membership/${consumer.consumer.id}`])
        },
        error => {
          this.loading = false; 
          this.loginForm.setErrors({ unauthenticated: true });
          this.loginForm.controls['password'].setValue('');
          this.alertService.error(error);
        });
    
  }

  onSignup(){
    this.customerId$.pipe(switchMap((customerId: string) => 
    this.apiService.signUp(this.numberValue.phoneNumber.value, customerId)))
      .pipe(first()).subscribe( 
      consumer => {
        this.loginForm.setErrors({ unauthenticated: false });
        
        this.consumer = consumer;
        alert("This phone number already exists: " + consumer.mobileNumber);

        // this.successModal = this.modalService.show(toOpen)
      },
      error => {
        this.loading = false; 
        this.loginForm.setErrors({ unauthenticated: true });
        this.alertService.error(error);
        this.customerStateService.setMobileNumber(this.numberValue.phoneNumber.value)
        this.router.navigate([`/customer/${this.customerStateService.customerId}/signup`])
      });
  
  }
  //Remove style on destroy
  ngOnDestroy(): void {
    document.body.style.removeProperty('overflow')
  }
}
