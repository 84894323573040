import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AppSettingsService } from './app-settings.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    AppSettingsService
  ]
})
export class AppSettingsModule {

  static forRoot(): ModuleWithProviders<AppSettingsModule> {
    return {
      ngModule: AppSettingsModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          multi: true,
          deps: [AppSettingsService],
          useFactory: (appSettingsService: AppSettingsService) => () => appSettingsService.initialize()
        }
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: AppSettingsModule) {
    if (parentModule) {
      throw new Error('AppSettingsModule is already loaded. Import it in the AppModule only');
    }
  }

}
