import { Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { PassPurchaseService } from 'src/app/pass-purchase/pass-purchase.service';
import { Debounce } from 'src/app/utils/method-decorators';
import { propertyOf } from 'src/app/utils/string-helpers';
import { ICustomerPass } from 'src/models/customer-pass';
import { ISiteProduct, ISiteProductDescription } from 'src/models/site-product';
import { ApiService } from 'src/services/api.service';
import { CustomerInformation } from 'src/models/customer';
import { CustomerStateService } from 'src/services/customer-state.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'dencar-product-banner',
    templateUrl: './product-banner.component.html',
    styleUrls: ['./product-banner.component.scss']
})
export class ProductBannerComponent implements  OnDestroy {
    private readonly onDestroy$ = new Subject<void>();

    public customerInformation: CustomerInformation = null;

    @ViewChild('passName', { static: true }) passName: ElementRef<HTMLSpanElement>;
    @ViewChild('sparkle', { static: true }) sparkle: ElementRef<HTMLDivElement>;
    @ViewChild('priceWrapper', { static: true }) priceWrapper: ElementRef<HTMLDivElement>;
    @ViewChild('nameWrapper', { static: true }) nameWrapper: ElementRef<HTMLDivElement>;

    @Input() pass: ICustomerPass;
    @Input() index: number;

    @Input() products: ISiteProduct[];
    @Input() showDetails: boolean;

    public passPrice: number;

    public tier: string;
    public productDescriptions: ISiteProductDescription[];
    public needsFirstSizing: boolean = true;

    private readonly priceResizeObserver: ResizeObserver;

    // customer dynamic styles/images
    public primaryTextColor: string;
    public tier4BannerColor: string;
    public tier3BannerColor: string;
    public tier2BannerColor: string;
    public tier1BannerColor: string;

    @HostListener('window:resize')
    @Debounce()
    windowResize() {
        this.sizeComponents();
    }

    constructor(
        private host: ElementRef<HTMLElement>,
        private passPurchaseService: PassPurchaseService,
        public customerStateService: CustomerStateService 
        ) {
        // Problem: Firefox and Safari seem to take longer to upate an element's client rect dimensions after populating
        // the element with text. This causes issues when we attempt to manually adjust the font sizing.
        // Solution: Use a ResizeObserver to know when the elements are actually resized so we can be sure that
        // the client rects on our elements are updated before we attempt to use those dimensions for figuring
        // out our best font size.
        this.priceResizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => this.windowResize());
    }
    ngOnInit(){
        this.customerStateService.currentCustomerInfo$.pipe(takeUntil(this.onDestroy$)).subscribe(
            customerInfo => {
                document.documentElement.style.setProperty('--theme-tier-4-banner-color', "#" + customerInfo.tier4Banner);
                document.documentElement.style.setProperty('--theme-tier-3-banner-color', "#" + customerInfo.tier3Banner);
                document.documentElement.style.setProperty('--theme-tier-2-banner-color', "#" + customerInfo.tier2Banner);
                document.documentElement.style.setProperty('--theme-tier-1-banner-color', "#" + customerInfo.tier1Banner);
            },
            error => console.error(error)
        );
        this.passPurchaseService.price.subscribe(
            data =>{
                this.passPrice = this.pass.passCost;
                //console.log(data);
            });

        this.customerStateService.currentCustomerInfo$.subscribe( customerDashboard =>{
            if(customerDashboard)
            {
                this.customerInformation = customerDashboard;
            }
        });
        if(this.products != null){

            if(this.products.find(t => t.productTemplateId === this.passPurchaseService.platinum)) { this.tier = 'platinum'; }
            else if(this.products.find(t => t.productTemplateId === this.passPurchaseService.gold)) { this.tier = 'gold'; }
            else if(this.products.find(t => t.productTemplateId === this.passPurchaseService.silver)) { this.tier = 'silver'; } 
            else if (this.products.find(t => t.productTemplateId === this.passPurchaseService.bronze)) { this.tier = 'bronze'; }
        }
        else if(this.pass != null){
            if(this.pass.products.find(t => t.productTemplateId === this.passPurchaseService.platinum)) { this.tier = 'platinum'; }
            else if(this.pass.products.find(t => t.productTemplateId === this.passPurchaseService.gold)) { this.tier = 'gold'; }
            else if(this.pass.products.find(t => t.productTemplateId === this.passPurchaseService.silver)) { this.tier = 'silver'; } 
            else if (this.pass.products.find(t => t.productTemplateId === this.passPurchaseService.bronze)) { this.tier = 'bronze'; }
        }
    }


    //wasn't able to find out what this was actually doing.
    // ngOnChanges(changes: SimpleChanges) {
    //     const productPropertyName = propertyOf<ProductBannerComponent>('products');
    //     const indexPropertyName = propertyOf<ProductBannerComponent>('index');

    //     if (changes.hasOwnProperty(productPropertyName)) {
    //         const change = changes[productPropertyName];
    //         if (change.currentValue === null || change.currentValue === undefined) {
    //             this.products = [];
    //         } else if (!(change.currentValue instanceof Array)) {
    //             this.products = new Array<ISiteProduct>(change.currentValue);
    //         }
           
    //     }

        // if (changes.hasOwnProperty(indexPropertyName)) {
        //     const change = changes[indexPropertyName];
        //     const currentValue = change.currentValue as number;
        //     switch (currentValue) {
        //         case 0:  this.tier = 'platinum'; break;
        //         case 1:  this.tier = 'gold'; break;
        //         case 2:  this.tier = 'silver'; break;
        //         case 3:  this.tier = 'bronze'; break;
        //         default: this.tier = 'primary'; break;
        //     }
        // }
    // }

    ngAfterViewInit() {
        this.priceResizeObserver.observe(this.priceWrapper.nativeElement);
        setTimeout(() => this.sizeComponents(), 300);
    }

    ngOnDestroy() {
        this.priceResizeObserver.disconnect();
    }

    /** Hides or shows the sparkle graphic and, if necessary, scales the font size of the pass name.
     * The goal is to always fit the pass name onto one line of text. */
    private sizeComponents(): void {
        const nameWrapper = this.nameWrapper.nativeElement;
        const nameSpan = nameWrapper.getElementsByClassName('name').item(0) as HTMLSpanElement;
        const sparkle = nameWrapper.getElementsByClassName('banner-sparkle').item(0) as HTMLDivElement;
        const priceWrapper = this.priceWrapper.nativeElement;

        const bannerWidth = nameWrapper.parentElement.getBoundingClientRect().width;
        const priceWrapperWidth = priceWrapper.getBoundingClientRect().width;
        const sideMargins = 30;     // pixel width for combined left margin and right margin
        const maxNameWrapperWidth = bannerWidth - priceWrapperWidth - sideMargins;

        var fontSizeValue = 1.75;      // initial and maximum font size of 2rem
        const fontSizeUnit = 'rem';

        // Create a clone of the wrapper so that we can test it at different font sizes to find one that fits
        const cloneNameWrapper = nameWrapper.cloneNode(true) as HTMLDivElement;    // deep copy the name wrapper
        const cloneNameSpan = cloneNameWrapper.getElementsByClassName('name').item(0) as HTMLSpanElement;
        const cloneSparkle = cloneNameWrapper.getElementsByClassName('banner-sparkle').item(0) as HTMLDivElement;
        cloneNameWrapper.classList.remove('d-none');
        cloneNameWrapper.style.position = 'absolute';
        cloneNameWrapper.style.visibility = 'hidden';
        cloneNameWrapper.style.whiteSpace = 'nowrap';
        cloneNameSpan.style.fontSize = `${fontSizeValue}${fontSizeUnit}`;
        cloneSparkle.classList.remove('d-none');
        nameWrapper.parentElement.appendChild(cloneNameWrapper);

        // Sizing priority
        // 1) Scale font size down to 1.7rem
        // 2) Hide the sparkle graphic
        // 3) Continue scaling font down (hard minium of 0.1rem)
        var showSparkle: boolean = true;
        var cloneWidth = cloneNameWrapper.getBoundingClientRect().width;
        if (cloneWidth > maxNameWrapperWidth) {
            while (cloneWidth > maxNameWrapperWidth && fontSizeValue > 1.7) {
                fontSizeValue -= 0.1;
                cloneNameSpan.style.fontSize = `${fontSizeValue}${fontSizeUnit}`;
                cloneWidth = cloneNameWrapper.getBoundingClientRect().width;
            }
            if (cloneWidth > maxNameWrapperWidth) {
                showSparkle = false;
                cloneSparkle.classList.add('d-none');
                cloneWidth = cloneNameWrapper.getBoundingClientRect().width;
            }
            if (cloneWidth > maxNameWrapperWidth) {
                while (cloneWidth > maxNameWrapperWidth && fontSizeValue > 0.1) {
                    fontSizeValue -= 0.1;
                    cloneNameSpan.style.fontSize = `${fontSizeValue}${fontSizeUnit}`;
                    cloneWidth = cloneNameWrapper.getBoundingClientRect().width;
                }
            }
        }

        if (showSparkle) {
            sparkle.classList.remove('d-none');
        } else {
            sparkle.classList.add('d-none');
        }
        nameSpan.style.fontSize = cloneNameSpan.style.fontSize;
        cloneNameWrapper.remove();
        this.needsFirstSizing = false;
    }
}
