import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AlertComponent } from "./alert/alert.component";
import { PageHeaderComponent } from "./page-header/page-header.component";
import { ProductBannerComponent } from "./product-banner/product-banner.component";
import { ProductDescriptionImageComponent } from "./product-description-image/product-description-image.component";
import { PurchaseFooterComponent } from "./purchase-footer/purchase-footer.component";
import { StaticHtmlComponent } from "./static-html/static-html.component";
import { BuyThisMonthlyPassSvgComponent } from "./svgs/buy-this-monthly-pass/buy-this-monthly-pass.svg.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from "@angular/router";
import { BackButtonComponent } from './back-button/back-button.component';

@NgModule({
    declarations: [
        AlertComponent,
        PageHeaderComponent,
        ProductBannerComponent,
        ProductDescriptionImageComponent,
        PurchaseFooterComponent,
        StaticHtmlComponent,
        BuyThisMonthlyPassSvgComponent,
        NavbarComponent,
        BackButtonComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule
    ],
    exports: [
        AlertComponent,
        PageHeaderComponent,
        ProductBannerComponent,
        ProductDescriptionImageComponent,
        PurchaseFooterComponent,
        StaticHtmlComponent,
        BuyThisMonthlyPassSvgComponent,
        NavbarComponent,
        BackButtonComponent
    ]
})
export class SharedModule { }